import React, { useEffect } from "react";
import { Container, Box, Typography, Button } from "@mui/material";
import Layout from "../components/base-structure/Layout";
import Seo from "../components/common/SEO";
import { Link } from "gatsby";
import ThankYouImg from "../images/ThankYouImage.svg";
import "../styles/ThankYou.scss";

const content = [
  {
    id: 1,
    metaTitle: "Thank You - Invocom",
    metaDescription:
      "We have received your request! Our sales team is excited & you'll soon hear from us meanwhile explore our features and pricing page to know more. ",
  },
];
const ThankYou = () => {
  // const [show, isShow] = useState();

  useEffect(() => {
    localStorage.setItem("popup", false);
  }, []);

  return (
    <Layout>
      {content.map((contents) => (
        <div key={contents.id}>
          <Seo
            title={contents.metaTitle}
            description={contents.metaDescription}
            tag="index"
            pageUrl="thank-you/"
          />
        </div>
      ))}
      <section className="thankYouWrapper">
        <Container>
          <Box className="thankYouContent">
            <Box className="thankYouImage">
              <img src={ThankYouImg} alt="" />
            </Box>
            <Box className="thankYouText">
              <Typography variant="h1">Thank You!</Typography>
              <Typography variant="h6">
                We have received your message. We’ll reach you out shortly.
              </Typography>
            </Box>
            <Box className="thankYouBtn">
              <Link to="/">
                <Button>Back to home</Button>
              </Link>
            </Box>
          </Box>
        </Container>
      </section>
    </Layout>
  );
};

export default ThankYou;
